/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export interface InputSchema {
  channels: Channels
  [k: string]: unknown
}

/** Store telegram channel info success */
export interface OutputSchema {
  ok?: boolean
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface Channels {
  chats?: Chat[]
  [k: string]: unknown
}

export function isChannels(v: unknown): v is Channels {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramChannels#Channels'
  )
}

export function validateChannels(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.telegramChannels#Channels', v)
}

export interface Chat {
  id?: number
  title?: string
  participants_count?: number
  /** is this a channel */
  broadcast?: boolean
  /** is this a super group */
  megagroup?: boolean
  [k: string]: unknown
}

export function isChat(v: unknown): v is Chat {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramChannels#chat'
  )
}

export function validateChat(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.telegramChannels#chat', v)
}
