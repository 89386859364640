/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export interface InputSchema {
  /** The telegram user id. */
  id: number
  /** The telegram firstname. */
  username: string
  /** The telegram lastname. */
  firstname: string
  /** The telegram lastname. */
  lastname: string
  /** The telegram user number. */
  phone_number: string
  telegram_json?: Telegram_json_ref
  photo?: Photo_ref
  [k: string]: unknown
}

/** Set telegram user info success. */
export interface OutputSchema {
  ok: boolean
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export class InvalidIdError extends XRPCError {
  constructor(src: XRPCError) {
    super(src.status, src.error, src.message, src.headers, { cause: src })
  }
}

export class InvalidUserNameError extends XRPCError {
  constructor(src: XRPCError) {
    super(src.status, src.error, src.message, src.headers, { cause: src })
  }
}

export function toKnownErr(e: any) {
  if (e instanceof XRPCError) {
    if (e.error === 'InvalidId') return new InvalidIdError(e)
    if (e.error === 'InvalidUserName') return new InvalidUserNameError(e)
  }

  return e
}

export interface Telegram_json_ref {
  [k: string]: unknown
}

export function isTelegram_json_ref(v: unknown): v is Telegram_json_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramUserBasicInfo#telegram_json_ref'
  )
}

export function validateTelegram_json_ref(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.telegramUserBasicInfo#telegram_json_ref',
    v,
  )
}

export interface Photo_ref {
  [k: string]: unknown
}

export function isPhoto_ref(v: unknown): v is Photo_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramUserBasicInfo#photo_ref'
  )
}

export function validatePhoto_ref(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.telegramUserBasicInfo#photo_ref',
    v,
  )
}
