/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {
  /** circle_id */
  circle_id: number
}

export type InputSchema = undefined

export interface OutputSchema {
  circle_name?: string
  circle_type?: number
  description?: string
  is_public?: boolean
  photo?: Photo_ref
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface Photo_ref {
  [k: string]: unknown
}

export function isPhoto_ref(v: unknown): v is Photo_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.getTelegramCircleInfo#photo_ref'
  )
}

export function validatePhoto_ref(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.getTelegramCircleInfo#photo_ref',
    v,
  )
}
