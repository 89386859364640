/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'
import * as ComAtprotoServerDefs from './defs'

export interface QueryParams {
  /** Post uri to fetch transaction details. */
  uri?: string
  /** DID of account to fetch transaction details. */
  actor?: string
}

export type InputSchema = undefined

/** Query all stake transaction details result */
export interface OutputSchema {
  trans: ComAtprotoServerDefs.StakeTransDetailGroup[]
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}
