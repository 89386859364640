/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'
import * as AppBskyActorDefs from '../../../app/bsky/actor/defs'

export interface InviteCode {
  code: string
  available: number
  disabled: boolean
  forAccount: string
  createdBy: string
  createdAt: string
  uses: InviteCodeUse[]
  [k: string]: unknown
}

export function isInviteCode(v: unknown): v is InviteCode {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#inviteCode'
  )
}

export function validateInviteCode(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#inviteCode', v)
}

export interface InviteCodeUse {
  usedBy: string
  usedAt: string
  [k: string]: unknown
}

export function isInviteCodeUse(v: unknown): v is InviteCodeUse {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#inviteCodeUse'
  )
}

export function validateInviteCodeUse(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#inviteCodeUse', v)
}

export interface BetTransDetailGroup {
  payer: string
  profile?: AppBskyActorDefs.ProfileViewBasic
  /** Total amount or total score */
  total: number
  trans: BetTransDetail[]
  [k: string]: unknown
}

export function isBetTransDetailGroup(v: unknown): v is BetTransDetailGroup {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#betTransDetailGroup'
  )
}

export function validateBetTransDetailGroup(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#betTransDetailGroup', v)
}

export interface BetTransDetail {
  transId: string
  uri: string
  payer: string
  profile?: AppBskyActorDefs.ProfileViewBasic
  payerWalletAddress: string
  amount: number
  /** Bet side, true for bet on, false for bet against */
  side: boolean
  createAt?: string
  updateAt?: string
  [k: string]: unknown
}

export function isBetTransDetail(v: unknown): v is BetTransDetail {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#betTransDetail'
  )
}

export function validateBetTransDetail(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#betTransDetail', v)
}

export interface DonateTransDetailGroup {
  payer: string
  profile?: AppBskyActorDefs.ProfileViewBasic
  trans: DonateTransDetail[]
  [k: string]: unknown
}

export function isDonateTransDetailGroup(
  v: unknown,
): v is DonateTransDetailGroup {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#donateTransDetailGroup'
  )
}

export function validateDonateTransDetailGroup(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#donateTransDetailGroup', v)
}

export interface DonateTransDetail {
  transId: string
  uri: string
  payer: string
  profile?: AppBskyActorDefs.ProfileViewBasic
  payerWalletAddress: string
  receiver: string
  receiverWalletAddress: string
  currency?: string
  amount: number
  method: string
  createAt?: string
  updateAt?: string
  [k: string]: unknown
}

export function isDonateTransDetail(v: unknown): v is DonateTransDetail {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#donateTransDetail'
  )
}

export function validateDonateTransDetail(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#donateTransDetail', v)
}

export interface BetTransSummary {
  uri: string
  judge_mode: string
  public_side: boolean
  bet_deadline: string
  totalAmount?: number
  totalBetOnAmount?: number
  totalBetAgainstAmount?: number
  result?: BetResultSummary
  [k: string]: unknown
}

export function isBetTransSummary(v: unknown): v is BetTransSummary {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#betTransSummary'
  )
}

export function validateBetTransSummary(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#betTransSummary', v)
}

export interface BetResultSummary {
  uri: string
  /** Bet result: 1 betOn win，2 betOn lose，3 draw, -1 invalid */
  result: number
  /** Balance amount for current user. A positive number means win, a negative number means lose. */
  balanceAmount: number
  /** Bet on total score */
  betOnScore: number
  /** Bet against total score */
  betAgainstScore: number
  [k: string]: unknown
}

export function isBetResultSummary(v: unknown): v is BetResultSummary {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#betResultSummary'
  )
}

export function validateBetResultSummary(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#betResultSummary', v)
}

export interface DonateTransSummary {
  uri: string
  profiles: AppBskyActorDefs.ProfileViewBasic[]
  totalDonor: number
  totalDonateAmount: number
  [k: string]: unknown
}

export function isDonateTransSummary(v: unknown): v is DonateTransSummary {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#donateTransSummary'
  )
}

export function validateDonateTransSummary(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#donateTransSummary', v)
}

export interface StakeTransDetailGroup {
  payer: string
  profile?: AppBskyActorDefs.ProfileViewBasic
  trans: StakeTransDetail[]
  [k: string]: unknown
}

export function isStakeTransDetailGroup(
  v: unknown,
): v is StakeTransDetailGroup {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#stakeTransDetailGroup'
  )
}

export function validateStakeTransDetailGroup(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#stakeTransDetailGroup', v)
}

export interface StakeTransDetail {
  transId: string
  uri: string
  payer: string
  profile?: AppBskyActorDefs.ProfileViewBasic
  payerWalletAddress: string
  currency?: string
  amount: number
  method: string
  createAt?: string
  updateAt?: string
  [k: string]: unknown
}

export function isStakeTransDetail(v: unknown): v is StakeTransDetail {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#stakeTransDetail'
  )
}

export function validateStakeTransDetail(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#stakeTransDetail', v)
}

export interface StakeTransSummary {
  uri: string
  profiles: AppBskyActorDefs.ProfileViewBasic[]
  totalStaker: number
  totalStakeAmount: number
  totalWithdrawer: number
  totalWithdrawAmount: number
  totalUnWithdrawAmount?: number
  platformEarning?: number
  [k: string]: unknown
}

export function isStakeTransSummary(v: unknown): v is StakeTransSummary {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#stakeTransSummary'
  )
}

export function validateStakeTransSummary(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#stakeTransSummary', v)
}

export interface Identifier {
  did?: string
  address: string
  message: string
  random_sdm: string
  random_sdn?: string
  app_token?: string
  publicKey?: string
  signature: string
  type?: string
  medium: string
  [k: string]: unknown
}

export function isIdentifier(v: unknown): v is Identifier {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#identifier'
  )
}

export function validateIdentifier(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#identifier', v)
}

export interface BindWalletAddress {
  id?: string
  name?: string
  did: string
  walletAddress: string
  verifySource: string
  verifySourceLogo: string
  paymentWallet: number
  createDt: string
  [k: string]: unknown
}

export function isBindWalletAddress(v: unknown): v is BindWalletAddress {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#bindWalletAddress'
  )
}

export function validateBindWalletAddress(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#bindWalletAddress', v)
}

export interface AccountInfo {
  accessJwt: string
  refreshJwt: string
  handle: string
  walletAddress?: string
  /** The DID of the new account. */
  did: string
  parentDid?: string
  /** Complete DID document. */
  didDoc?: {}
  [k: string]: unknown
}

export function isAccountInfo(v: unknown): v is AccountInfo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#accountInfo'
  )
}

export function validateAccountInfo(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#accountInfo', v)
}

export interface SubAccountInfo {
  index?: string
  handle: string
  walletAddress?: string
  parentDid?: string
  /** The DID of the new account. */
  did: string
  defaultMask?: number
  [k: string]: unknown
}

export function isSubAccountInfo(v: unknown): v is SubAccountInfo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#subAccountInfo'
  )
}

export function validateSubAccountInfo(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#subAccountInfo', v)
}

export interface UserTransRecordInfo {
  month: string
  records: TransRecord[]
  [k: string]: unknown
}

export function isUserTransRecordInfo(v: unknown): v is UserTransRecordInfo {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#UserTransRecordInfo'
  )
}

export function validateUserTransRecordInfo(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#UserTransRecordInfo', v)
}

export interface TransRecord {
  transId?: string
  uri?: string
  transType?: number
  payer?: string
  payerWalletAddress?: string
  receiver?: string
  receiverWalletAddress?: string
  tokenAddress?: string
  amount?: string
  amountFlag?: number
  remark?: string
  createAt?: string
  updateAt?: string
  [k: string]: unknown
}

export function isTransRecord(v: unknown): v is TransRecord {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.defs#transRecord'
  )
}

export function validateTransRecord(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.defs#transRecord', v)
}
