/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export interface InputSchema {
  /** The prepared transaction's id/hash, like: 0x....(size=2+64). */
  id: string
  /** The user did, like: did:plc:fk6ef7us7eimkwhatrqdm3cv. */
  payer: string
  /** Contract method identity. */
  method:
    | 'insider-stake.stake'
    | 'insider-stake.withdraw'
    | 'insider-donation.donate'
    | 'insider-bet.create'
    | 'insider-bet.bet'
  /**
   * Optional payload json string , like: JsonObject = `{${string}}`.
   *  1 Donate(insider-donation.donate):
   *   {receiver: receiverDid}
   *  2 Bet create(insider-bet.create):{
   *     judge_mode: committee_ruling|popularity,
   *     public_side: true|false.  public side switch: true for public, false for hidden.
   *     side: <optional, required when create with bet>true|false
   *  }
   *  3 Betting(insider-bet.bet):{
   *     side: true|false.  true for bet on, false for bet against
   *  }
   */
  payload?: string
  [k: string]: unknown
}

export interface OutputSchema {
  /** The prepared transaction's id/hash, like: 0x....(size=34). */
  id?: string
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}
