/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export interface InputSchema {
  /** input circle id */
  circle_id: number
  [k: string]: unknown
}

export interface OutputSchema {
  circle: Circle
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface Circle {
  users?: User[]
  [k: string]: unknown
}

export function isCircle(v: unknown): v is Circle {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.requestCircleUser#Circle'
  )
}

export function validateCircle(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.requestCircleUser#Circle', v)
}

export interface User {
  /** The telegram user id. */
  id?: number
  /** The telegram firstname. */
  username?: string
  /** The telegram lastname. */
  lastname?: string
  /** The telegram user number. */
  phone_number?: string
  telegram_json?: Telegram_json_ref
  photo?: Photo_ref
  [k: string]: unknown
}

export function isUser(v: unknown): v is User {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.requestCircleUser#user'
  )
}

export function validateUser(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.requestCircleUser#user', v)
}

export interface Telegram_json_ref {
  [k: string]: unknown
}

export function isTelegram_json_ref(v: unknown): v is Telegram_json_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.requestCircleUser#telegram_json_ref'
  )
}

export function validateTelegram_json_ref(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.requestCircleUser#telegram_json_ref',
    v,
  )
}

export interface Photo_ref {
  [k: string]: unknown
}

export function isPhoto_ref(v: unknown): v is Photo_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.requestCircleUser#photo_ref'
  )
}

export function validatePhoto_ref(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.requestCircleUser#photo_ref', v)
}
