/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export interface InputSchema {
  count: number
  participants: ParticipantObj[]
  chats: Chat[]
  users: User[]
  [k: string]: unknown
}

/** Store telegram channel info success */
export interface OutputSchema {
  ok?: boolean
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface ParticipantObj {
  user_id?: number
  [k: string]: unknown
}

export function isParticipantObj(v: unknown): v is ParticipantObj {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramParticipants#participantObj'
  )
}

export function validateParticipantObj(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.telegramParticipants#participantObj',
    v,
  )
}

export interface Chat {
  id?: number
  title?: string
  participants_count?: number
  [k: string]: unknown
}

export function isChat(v: unknown): v is Chat {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramParticipants#chat'
  )
}

export function validateChat(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.telegramParticipants#chat', v)
}

export interface User {
  id?: number
  username?: string
  firstName?: string
  lastName?: string
  /** The telegram user number. */
  phone_number?: string
  telegram_json?: Telegram_json_ref
  photo?: Photo_ref
  [k: string]: unknown
}

export function isUser(v: unknown): v is User {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramParticipants#user'
  )
}

export function validateUser(v: unknown): ValidationResult {
  return lexicons.validate('com.atproto.server.telegramParticipants#user', v)
}

export interface Telegram_json_ref {
  [k: string]: unknown
}

export function isTelegram_json_ref(v: unknown): v is Telegram_json_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramParticipants#telegram_json_ref'
  )
}

export function validateTelegram_json_ref(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.telegramParticipants#telegram_json_ref',
    v,
  )
}

export interface Photo_ref {
  [k: string]: unknown
}

export function isPhoto_ref(v: unknown): v is Photo_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramParticipants#photo_ref'
  )
}

export function validatePhoto_ref(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.telegramParticipants#photo_ref',
    v,
  )
}
