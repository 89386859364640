/**
 * GENERATED CODE - DO NOT MODIFY
 */
import { HeadersMap, XRPCError } from '@atproto/xrpc'
import { ValidationResult, BlobRef } from '@atproto/lexicon'
import { isObj, hasProp } from '../../../../util'
import { lexicons } from '../../../../lexicons'
import { CID } from 'multiformats/cid'

export interface QueryParams {}

export interface InputSchema {
  circle_name?: string
  circle_type?: number
  photo?: Photo_ref
  description?: string
  is_public?: boolean
  [k: string]: unknown
}

/** Store circle info. */
export interface OutputSchema {
  circle_id: string
  [k: string]: unknown
}

export interface CallOptions {
  signal?: AbortSignal
  headers?: HeadersMap
  qp?: QueryParams
  encoding?: 'application/json'
}

export interface Response {
  success: boolean
  headers: HeadersMap
  data: OutputSchema
}

export function toKnownErr(e: any) {
  return e
}

export interface Photo_ref {
  [k: string]: unknown
}

export function isPhoto_ref(v: unknown): v is Photo_ref {
  return (
    isObj(v) &&
    hasProp(v, '$type') &&
    v.$type === 'com.atproto.server.telegramCircleInfoAdd#photo_ref'
  )
}

export function validatePhoto_ref(v: unknown): ValidationResult {
  return lexicons.validate(
    'com.atproto.server.telegramCircleInfoAdd#photo_ref',
    v,
  )
}
